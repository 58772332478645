$color_white: var(--white);

$color_black: var(--black);
$color_black_100: var(--black-100);
$color_black_200: var(--black-200);
$color_black_300: var(--black-300);
$color_black_400: var(--black-400);
$color_black_500: var(--black-500);

$color_grey_100: var(--grey-100);
$color_grey_200: var(--grey-200);
$color_grey_300: var(--grey-300);
$color_grey_400: var(--grey-400);
$color_grey_500: var(--grey-500);

$color_blue_green: var(--blue-green);
$color_blue_green_100: var(--blue-green-100);
$color_blue_green_200: var(--blue-green-200);
$color_blue_green_300: var(--blue-green-300);
$color_blue_green_400: var(--blue-green-400);
$color_blue_green_500: var(--blue-green-500);
$color_blue_green_600: var(--blue-green-600);

$color_yellow_100: var(--yellow-100);
$color_yellow_200: var(--yellow-200);

$color_red_100: var(--red-100);
$color_red_200: var(--red-200);
$color_red_300: var(--red-300);

$color_green_100: var(--green-100);
$color_green_200: var(--green-200);

$font-family_light: var(--font-light);
$font-family_regular: var(--font-regular);
$font-family_medium: var(--font-medium);
$font-family_bold: var(--font-bold);

$color_primary: $color_blue_green_200
