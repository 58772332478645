@use "src/common";

.modal-generic {
  width: 90% !important;
  max-width: 560px;
  height: fit-content;
  .ant-modal-content {
    padding: 29px 27px;
  }
  .ant-modal-close-icon {
    color: common.$color_black;
    font-size: 20px;
  }
  .ant-modal-close {
    width: 40px;
    height: 40px;
    border-radius: 40px;
  }
}
.modal-generic-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal-generic__title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 8px;
    color: common.$color_blue_green_100;
    font-family: common.$font-family_medium;
    font-size: 24px;
    padding: 16px 0;
    margin-bottom: 12px;
  }
  .modal-generic__desc {
    display: block;
    text-align: center;
    font-family: common.$font-family_regular;
    font-size: 16px;
    line-height: 120%;
    margin-bottom: 32px;
    color: common.$color_grey_100;
    p {
      color: common.$color_grey_100;
    }
    form {
      width: 100%;
      min-width: 500px;
    }
  }
  .modal-generic__cta {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    .btn-gen {
      &.btn-gen-secondary {
        border: 1px solid common.$color_grey_300;
      }
    }
  }
}
.modal-attach {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .modal-generic {
    width: 100% !important;
    max-width: 100% !important;
    position: absolute;
    top: auto;
    bottom: 0;
    padding: 0;
    margin: 0 !important;
    .ant-modal-content {
      border-radius: 8px 8px 0 0;
    }
  }
}
